import React, { useState, useEffect } from 'react'

const CookieModal = () => {
  // State to control the visibility of the modal
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    // Check localStorage to see if the user has already accepted cookies
    const hasAcceptedCookies = localStorage.getItem('acceptedCookies') === 'true'
    setIsVisible(!hasAcceptedCookies)
  }, []);

  // Handler for accepting cookies
  const handleAcceptCookies = () => {
    // Set the cookie in localStorage
    localStorage.setItem('acceptedCookies', 'true')
    setIsVisible(false)
  }

  // Handler for getting more info about cookies
  const handleMoreInfo = () => {
    setIsVisible(false)
  };

  if (!isVisible) return null

  return (
    <div className="row">
      <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
        <div className="text-center sg-pb-15px site-header__leader">
          <p className="text-white">
            This website uses cookies. We use cookies to make sure you get the best experience on our website. Please read our 
            <a className="text-underline text-white" href="en/about-us/privacy-policies.html">privacy</a> 
            and 
            <a className="text-underline text-white" href="en/about-us/cookie-policies.html">cookie policy</a>.
          </p>
          <div className="sg-mt-15px">
            <a id="accept_cookie" className="btn btn-default" onClick={handleAcceptCookies}>
              Yes, I accept cookies
            </a>
            <a className="btn btn-default" onClick={handleMoreInfo}>
              No, I want more info
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieModal
