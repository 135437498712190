import React from 'react'

function Stores() {
  return (
    <>
  <div className="constant-dots">
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        left: 0,
        bottom: 0,
        backgroundColor: "#000",
        width: 30,
        height: 30,
        lineHeight: 30,
        color: "#fff",
        textAlign: "center",
        display: "none"
      }}
    >
      <span id="breakpoint" />
    </div>
    <div className="site">
      <img
        src="http://mena-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_236257&src.rand=[timestamp]"
        style={{ display: "none" }}
      />
      <div className="site-content">
        <div className="site-sections store-directory">
          <nav className="site-sections__menu hidden-xs">
            <ul>
              <li>
                <a href="fashion-avenue.html">Fashion avenue</a>
              </li>
              <li>
                <a href="fountain-view.html">Fountain views</a>
              </li>
              <li>
                <a href="campaign/chinatown.html">Chinatown</a>
              </li>
              <li>
                <a href="offers.html">Offers</a>
              </li>
              <li>
                <a href="events.html">Events</a>
              </li>
            </ul>
          </nav>
          <section className="site-section">
            <div className="container sg-mt-20px-xs">
              <div className="row">
                <div className="col-xs-24 sg-mt-30px sg-mb-20px">
                  <ul
                    className="breadcrumb breadcrumb--abs hidden-xs hidden-sm"
                    itemScope=""
                    itemType="https://schema.org/BreadcrumbList"
                  >
                    <li
                      itemProp="itemListElement"
                      itemScope=""
                      itemType="https://schema.org/ListItem"
                    >
                      <a itemProp="item" href="index.html">
                        <span itemProp="name">Home</span>
                      </a>
                      <meta itemProp="position" content={1} />
                    </li>
                    <li
                      itemProp="itemListElement"
                      itemScope=""
                      itemType="https://schema.org/ListItem"
                    >
                      <span className="arrow" />
                      <span itemProp="name">Store Directory</span>
                      <meta itemProp="position" content={2} />
                    </li>
                  </ul>
                  <h3 className="text-center">Store Directory</h3>
                </div>
              </div>
            </div>
          </section>
          <section className="site-section featured" id="store-directory-ar2">
            <div className="container sg-mb-5px">
              <div className="row">
                <div className="col-xs-24">
                  <h5 className="text-center text-uppercase bordered sg-mb-30px">
                    <span>Our Picks</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="container no-gutter-md sg-mb-30px">
              <div className="slick-slider-container">
                <div className="slick-slider-container__row">
                  <div className="row">
                    <div
                      className="section-featured-shops"
                      data-filter-dine="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="featured-stores-for-tablets" />
          </section>
          <section className="site-section site-section-bordered">
            <a name="search" id="search" />
            <div className="container sg-mb-25px sg-mt-25px">
              <div className="row" id="directory_twig">
                <div className="col-sm-15">
                  <form
                    action="#"
                    id="search-shop-directory-form"
                    className="search-form sg-mb-15px"
                  >
                    <fieldset>
                      <input
                        name="q"
                        className="input--search"
                        id="search-shop-directory"
                        placeholder="Search for shop or brand"
                        type="search"
                        autoComplete="off"
                      />
                      <span className="site-search-btn__icon icon-search" />
                      <button className="search-submit search-shop-directory-button disabled">
                        <span className="icon icon-search" />
                      </button>
                    </fieldset>
                  </form>
                </div>
                <div className="col-sm-6 col-sm-offset-1 hidden-xs">
                  <label className="styled-checkbox sg-mt-5px">
                    <input
                      className="styled-checkbox__hidden"
                      data-checkbox-shop-filter="offers"
                      type="checkbox"
                      name="checkbox-button"
                    />
                    <span className="styled-checkbox__button" />
                    <span className="styled-checkbox__label">
                      Show stores only
                      <br />
                      with offers
                    </span>
                  </label>
                </div>
              </div>
              <div className="row" id="search_twig_cat">
                <div className="col-md-5 col-sm-8 col-xs-24">
                  <div className="select" data-select-group="categories">
                    <div className="select-styled">
                      <strong className="inner">Category</strong>
                      <span className="arrow" />
                    </div>
                    <ul
                      className="sortcategories select-options"
                      style={{ display: "none" }}
                    >
                      <li rel="hide" data-id="hide">
                        Category
                      </li>
                      <li
                        data-id="all"
                        data-match="false"
                        data-value="Category"
                      >
                        View all
                      </li>
                      <li
                        data-id={1}
                        data-match="true"
                        data-value="Art & Photography"
                      >
                        Art &amp; Photography
                      </li>
                      <li
                        data-id={2}
                        data-match="true"
                        data-value="Attractions"
                      >
                        Attractions
                      </li>
                      <li
                        data-id={3}
                        data-match="true"
                        data-value="Banks & Financial Services"
                      >
                        Banks &amp; Financial Services
                      </li>
                      <li
                        data-id={4}
                        data-match="true"
                        data-value="Beauty & Fragrances"
                      >
                        Beauty &amp; Fragrances
                      </li>
                      <li
                        data-id={5}
                        data-match="true"
                        data-value="Books, Cards & Stationery"
                      >
                        Books, Cards &amp; Stationery
                      </li>
                      <li
                        data-id={7}
                        data-match="true"
                        data-value="Department Store"
                      >
                        Department Store
                      </li>
                      <li
                        data-id={8}
                        data-match="true"
                        data-value="Dry Cleaning"
                      >
                        Dry Cleaning
                      </li>
                      <li
                        data-id={9}
                        data-match="true"
                        data-value="Electronics"
                      >
                        Electronics
                      </li>
                      <li data-id={10} data-match="true" data-value="Eyewear">
                        Eyewear
                      </li>
                      <li
                        data-id={11}
                        data-match="true"
                        data-value="Fashion - Women"
                      >
                        Fashion - Women
                      </li>
                      <li
                        data-id={12}
                        data-match="true"
                        data-value="Fashion - Men"
                      >
                        Fashion - Men
                      </li>
                      <li
                        data-id={13}
                        data-match="true"
                        data-value="Fashion - Children"
                      >
                        Fashion - Children
                      </li>
                      <li data-id={15} data-match="true" data-value="Florist">
                        Florist
                      </li>
                      <li data-id={17} data-match="true" data-value="Gifts">
                        Gifts
                      </li>
                      <li
                        data-id={18}
                        data-match="true"
                        data-value="Health & Nutrition"
                      >
                        Health &amp; Nutrition
                      </li>
                      <li data-id={19} data-match="true" data-value="Home">
                        Home
                      </li>
                      <li
                        data-id={20}
                        data-match="true"
                        data-value="Music & Movies"
                      >
                        Music &amp; Movies
                      </li>
                      <li
                        data-id={21}
                        data-match="true"
                        data-value="Photo Development"
                      >
                        Photo Development
                      </li>
                      <li data-id={22} data-match="true" data-value="Shoes">
                        Shoes
                      </li>
                      <li data-id={23} data-match="true" data-value="Toys">
                        Toys
                      </li>
                      <li
                        data-id={24}
                        data-match="true"
                        data-value="Travel & Luggage "
                      >
                        Travel &amp; Luggage{" "}
                      </li>
                      <li
                        data-id={25}
                        data-match="true"
                        data-value="Jewellery & Watches"
                      >
                        Jewellery &amp; Watches
                      </li>
                      <li data-id={95} data-match="true" data-value="Chocolate">
                        Chocolate
                      </li>
                      <li
                        data-id={98}
                        data-match="true"
                        data-value="Confectionary"
                      >
                        Confectionary
                      </li>
                      <li data-id={149} data-match="true" data-value="Kiosks">
                        Kiosks
                      </li>
                      <li data-id={158} data-match="true" data-value="Services">
                        Services
                      </li>
                      <li
                        data-id={164}
                        data-match="true"
                        data-value="Telecommunications"
                      >
                        Telecommunications
                      </li>
                      <li
                        data-id={170}
                        data-match="true"
                        data-value="Supermarket"
                      >
                        Supermarket
                      </li>
                      <li data-id={173} data-match="true" data-value="The Souk">
                        The Souk
                      </li>
                      <li
                        data-id={176}
                        data-match="true"
                        data-value="Metro Link Shopping"
                      >
                        Metro Link Shopping
                      </li>
                      <li
                        data-id={179}
                        data-match="true"
                        data-value="Fashion Avenue"
                      >
                        Fashion Avenue
                      </li>
                      <li
                        data-id={185}
                        data-match="true"
                        data-value="New Stores"
                      >
                        New Stores
                      </li>
                      <li
                        data-id={206}
                        data-match="true"
                        data-value="Entertain"
                      >
                        Entertain
                      </li>
                      <li data-id={209} data-match="true" data-value="Hotels">
                        Hotels
                      </li>
                      <li
                        data-id={229}
                        data-match="true"
                        data-value="Play Area"
                      >
                        Play Area
                      </li>
                      <li
                        data-id={253}
                        data-match="true"
                        data-value="Education"
                      >
                        Education
                      </li>
                      <li
                        data-id={267}
                        data-match="true"
                        data-value="Mini Mart"
                      >
                        Mini Mart
                      </li>
                      <li
                        data-id={268}
                        data-match="true"
                        data-value="Beauty Salon"
                      >
                        Beauty Salon
                      </li>
                      <li
                        data-id={269}
                        data-match="true"
                        data-value="Sportswear & Fitness"
                      >
                        Sportswear &amp; Fitness
                      </li>
                      <li
                        data-id={270}
                        data-match="true"
                        data-value="Jewellery"
                      >
                        Jewellery
                      </li>
                      <li data-id={272} data-match="true" data-value="Watches">
                        Watches
                      </li>
                      <li data-id={275} data-match="true" data-value="Fashion">
                        Fashion
                      </li>
                      <li data-id={276} data-match="true" data-value="Footwear">
                        Footwear
                      </li>
                      <li data-id={277} data-match="true" data-value="Gourmet">
                        Gourmet
                      </li>
                      <li data-id={278} data-match="true" data-value="Luxury">
                        Luxury
                      </li>
                      <li
                        data-id={279}
                        data-match="true"
                        data-value="Optical & Sunglasses"
                      >
                        Optical &amp; Sunglasses
                      </li>
                      <li
                        data-id={280}
                        data-match="true"
                        data-value="Beauty, Perfumes & Cosmetics"
                      >
                        Beauty, Perfumes &amp; Cosmetics
                      </li>
                      <li data-id={282} data-match="true" data-value="Shopping">
                        Shopping
                      </li>
                      <li data-id={283} data-match="true" data-value="Dining">
                        Dining
                      </li>
                      <li data-id={284} data-match="true" data-value="Wellness">
                        Wellness
                      </li>
                    </ul>
                  </div>
                  <select className="no-transform shops hidden-xs">
                    <option selected="" disabled="">
                      Category
                    </option>
                    <option value="all">View all</option>
                    <option value={1}>Art &amp; Photography</option>
                    <option value={2}>Attractions</option>
                    <option value={3}>Banks &amp; Financial Services</option>
                    <option value={4}>Beauty &amp; Fragrances</option>
                    <option value={5}>Books, Cards &amp; Stationery</option>
                    <option value={7}>Department Store</option>
                    <option value={8}>Dry Cleaning</option>
                    <option value={9}>Electronics</option>
                    <option value={10}>Eyewear</option>
                    <option value={11}>Fashion - Women</option>
                    <option value={12}>Fashion - Men</option>
                    <option value={13}>Fashion - Children</option>
                    <option value={15}>Florist</option>
                    <option value={17}>Gifts</option>
                    <option value={18}>Health &amp; Nutrition</option>
                    <option value={19}>Home</option>
                    <option value={20}>Music &amp; Movies</option>
                    <option value={21}>Photo Development</option>
                    <option value={22}>Shoes</option>
                    <option value={23}>Toys</option>
                    <option value={24}>Travel &amp; Luggage </option>
                    <option value={25}>Jewellery &amp; Watches</option>
                    <option value={95}>Chocolate</option>
                    <option value={98}>Confectionary</option>
                    <option value={149}>Kiosks</option>
                    <option value={158}>Services</option>
                    <option value={164}>Telecommunications</option>
                    <option value={170}>Supermarket</option>
                    <option value={173}>The Souk</option>
                    <option value={176}>Metro Link Shopping</option>
                    <option value={179}>Fashion Avenue</option>
                    <option value={185}>New Stores</option>
                    <option value={206}>Entertain</option>
                    <option value={209}>Hotels</option>
                    <option value={229}>Play Area</option>
                    <option value={253}>Education</option>
                    <option value={267}>Mini Mart</option>
                    <option value={268}>Beauty Salon</option>
                    <option value={269}>Sportswear &amp; Fitness</option>
                    <option value={270}>Jewellery</option>
                    <option value={272}>Watches</option>
                    <option value={275}>Fashion</option>
                    <option value={276}>Footwear</option>
                    <option value={277}>Gourmet</option>
                    <option value={278}>Luxury</option>
                    <option value={279}>Optical &amp; Sunglasses</option>
                    <option value={280}>
                      Beauty, Perfumes &amp; Cosmetics
                    </option>
                    <option value={282}>Shopping</option>
                    <option value={283}>Dining</option>
                    <option value={284}>Wellness</option>
                  </select>
                </div>
                <div className="col-md-5 col-sm-8 col-xs-24">
                  <div
                    className="select subcategories"
                    data-select-group="subcategories"
                    data-default-text="Subcategory"
                  >
                    <div className="select-styled">
                      <strong className="inner">Subcategory</strong>
                      <span className="arrow" />
                    </div>
                    <ul
                      className="selectbox-subcategories select-options"
                      style={{ display: "none" }}
                    ></ul>
                  </div>
                </div>
                <div className="col-md-5 col-sm-8 col-xs-24">
                  <div className="select" data-select-group="shop-alphabet">
                    <div className="select-styled">
                      <strong className="inner">View by 0-9</strong>
                      <span className="arrow" />
                    </div>
                    <ul className="select-options" style={{ display: "none" }}>
                      <li
                        data-id="all"
                        data-match="false"
                        data-value="View by A-Z"
                      >
                        View all
                      </li>
                      <li data-id={0} data-match="true" data-value={0}>
                        [0-9]
                      </li>
                      <li data-id="A" data-match="true" data-value="A">
                        A
                      </li>
                      <li data-id="B" data-match="true" data-value="B">
                        B
                      </li>
                      <li data-id="C" data-match="true" data-value="C">
                        C
                      </li>
                      <li data-id="D" data-match="true" data-value="D">
                        D
                      </li>
                      <li data-id="E" data-match="true" data-value="E">
                        E
                      </li>
                      <li data-id="F" data-match="true" data-value="F">
                        F
                      </li>
                      <li data-id="G" data-match="true" data-value="G">
                        G
                      </li>
                      <li data-id="H" data-match="true" data-value="H">
                        H
                      </li>
                      <li data-id="I" data-match="true" data-value="I">
                        I
                      </li>
                      <li data-id="J" data-match="true" data-value="J">
                        J
                      </li>
                      <li data-id="K" data-match="true" data-value="K">
                        K
                      </li>
                      <li data-id="L" data-match="true" data-value="L">
                        L
                      </li>
                      <li data-id="M" data-match="true" data-value="M">
                        M
                      </li>
                      <li data-id="N" data-match="true" data-value="N">
                        N
                      </li>
                      <li data-id="O" data-match="true" data-value="O">
                        O
                      </li>
                      <li data-id="P" data-match="true" data-value="P">
                        P
                      </li>
                      <li data-id="Q" data-match="true" data-value="Q">
                        Q
                      </li>
                      <li data-id="R" data-match="true" data-value="R">
                        R
                      </li>
                      <li data-id="S" data-match="true" data-value="S">
                        S
                      </li>
                      <li data-id="T" data-match="true" data-value="T">
                        T
                      </li>
                      <li data-id="U" data-match="true" data-value="U">
                        U
                      </li>
                      <li data-id="V" data-match="true" data-value="V">
                        V
                      </li>
                      <li data-id="W" data-match="true" data-value="W">
                        W
                      </li>
                      <li data-id="X" data-match="true" data-value="X">
                        X
                      </li>
                      <li data-id="Y" data-match="true" data-value="Y">
                        Y
                      </li>
                      <li data-id="Z" data-match="true" data-value="Z">
                        Z
                      </li>
                    </ul>
                  </div>
                </div>
                <select className="no-transform az  hidden-xs">
                  <option value="all">View all</option>
                  <option value="[0-9]">[0-9]</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                  <option value="F">F</option>
                  <option value="G">G</option>
                  <option value="H">H</option>
                  <option value="I">I</option>
                  <option value="J">J</option>
                  <option value="K">K</option>
                  <option value="L">L</option>
                  <option value="M">M</option>
                  <option value="N">N</option>
                  <option value="O">O</option>
                  <option value="P">P</option>
                  <option value="Q">Q</option>
                  <option value="R">R</option>
                  <option value="S">S</option>
                  <option value="T">T</option>
                  <option value="U">U</option>
                  <option value="V">V</option>
                  <option value="W">W</option>
                  <option value="X">X</option>
                  <option value="Y">Y</option>
                  <option value="Z">Z</option>
                </select>
              </div>
            </div>
          </section>
          <section className="site-section">
            <div className="container sg-mb-25px sg-mt-40px">
              <div className="row sg-mb-20px block-grid-legend">
                <div className="col-sm-12">
                  <p
                    className="store-display-indicator"
                    style={{
                      display: "none !important",
                      color: "#FFF !important"
                    }}
                    data-label-showing-indicator="Showing %s1% Results"
                  >
                    Showing Results
                  </p>
                  <div
                    className="nosearch-result col-md-24 text-center hidden"
                    style={{ minHeight: "0px !important" }}
                  >
                    <h3 className="nosearch-result__message sg-mb-15px text-center">
                      New updates will be posted soon
                    </h3>
                    {/*<p class="nosearch-result__message sg-mb-15px"> New updates will be posted soon</p>*/}
                    {/*<p class="nosearch-result__message sg-mb-15px"> Sorry, we couldn&#039;t find a shop to match your search.</p>*/}
                    {/*<p class="nosearch-result__suggestion sg-mb-5px">Did you mean <span class=""><a href="#" class="nosearch-result__suggestion--word"></a></span></p>*/}
                    {/*<p class="nosearch-result__suggestion--or sg-mb-5px">Or</p>*/}
                    {/*<p class="nosearch-result__suggestion--tryagain sg-mb-10px">Please try a new search entry</p>*/}
                  </div>
                </div>
                <div className="col-sm-12">
                  <p className="pull-right ">
                    <i className="icon icon-tag" />{" "}
                    <span className="inner sg-mr-15px">Store Offers</span>
                    <i className="icon icon-new" /> <i className="new-icon" />{" "}
                    <span className="inner">Accepts Emaar Gift Card</span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="container sg-mb-25px sg-mt-40px"
              style={{ maxWidth: 992 }}
              id="stores-search-result-container-parent"
            >
              <div className="row">
                {/* the template for the store item rendered trough handlebar.js */}
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered hidden"
                    id="matched-stores-head"
                  >
                    <span>Matched Results</span>
                  </h5>
                </div>
                <div className="col-xs-24">
                  <ul className="plain-block-grid" />
                </div>
              </div>
              <div className="row pagination">
                <a
                  href="#"
                  id="btn-store-directory-load-more"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
            <div
              className="container sg-mb-25px sg-mt-40px hidden"
              style={{ maxWidth: 992 }}
              id="related-stores-search-result-container-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5 className="text-center text-uppercase bordered">
                    <span>Related Results</span>
                  </h5>
                </div>
                <div className="col-xs-24">
                  <ul className="plain-block-grid" />
                </div>
              </div>
              <div className="row pagination">
                <a
                  href="#"
                  id="btn-related-store-directory-load-more"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
          </section>
          <section className="site-section">
            <a
              href="#search"
              id="refineStores"
              data-refinesearch-btn=""
              className="link--back-to-top hidden"
              target="_self"
              data-scroll-to=""
            >
              <i className="arrow" /> <span>Refine search</span>
            </a>
          </section>
          <section
            className="site-section"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="container sg-mb-25px">
              <div className="row">
                <div className="col-xs-24">
                  <div className="visible-xs">
                    <h2 className="text-center sg-mt-40px sg-mb-25px">
                      Discover Events &amp; Offers
                    </h2>
                  </div>
                  <div className="hidden-xs">
                    <h3 className="text-center sg-mt-40px">
                      Discover Events &amp; Offers
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container no-gutter-md sg-mb-30pxm "
              id="home_view4"
            >
              <div className="slick-slider-container">
                <div className="slick-slider-container__row slick-slider-with-text">
                  <div className="row">
                    <div
                      className="slick-events-offers-slider pull-nav-up diap-dots"
                      data-prefilter='{"excludeShopFilter":[101,221,224,227,281]}'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row hidden-xs sg-mb-40px">
                <div className="col-xs-24 text-center">
                  <div className="sg-mt-20px" id="home_view5">
                    <a
                      href="events.html"
                      className="btn btn-default btn-block-sm margin-right-xs-px"
                    >
                      Discover events
                    </a>
                    <a
                      href="offers.html"
                      className="btn btn-default btn-block-sm"
                    >
                      Discover offers
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="visible-xs">
              <br />
            </div>
          </section>
          <section className="site-section section-services-class">
            <div className="container sg-mb-50px">
              <div className="row">
                <div className="col-sm-12">
                  <div className="visible-xs">
                    <br />
                    <br />
                  </div>
                  <div className="entry-content">
                    <h3 className="sg-mb-20px">Explore The Mall</h3>
                    <div className="row">
                      <div className="col-xs-24">
                        <p>
                          Every store covered. Plan your visit with our
                          interactive map.
                        </p>
                      </div>
                      <div className="col-xs-24">
                        <div
                          className="block col-sm-24"
                          data-block=""
                          style={{ height: 250 }}
                        >
                          <div className="map-overlay" />
                          {/*<iframe id="michello-map" class="map-embed" src="/map2.php" width="100%" height="100%" frameborder=0></iframe>*/}
                          <iframe
                            id="michello-map"
                            className="map-embed"
                            src="https://thedubaimall.com/mappedin-web.php"
                            width="100%"
                            height="100%"
                            frameBorder={0}
                          />{" "}
                        </div>
                      </div>
                    </div>
                    <a href="map.html" className="btn-link">
                      <p>
                        <strong>View interactive map</strong>
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="entry-content">
                    <div className="visible-xs">
                      <br />
                      <br />
                    </div>
                    <h3
                      className="sg-mb-20px"
                      style={{ fontSize: "22px !important" }}
                    >
                      Visitor info
                    </h3>
                    <p>
                      Located throughout Dubai Mall you will find eight guest
                      service desks where our team will be delighted to assist
                      you with any of your queries or questions.
                      <br />
                      <br />
                      To make your experience exceptional, make the most of our
                      range of services, including -
                    </p>
                    <p>
                      -Free WiFi
                      <br />
                      -Emaar gift cards
                      <br />
                      -Valet parking
                      <br />
                      -Delivery Service
                      <br />
                    </p>
                    <a
                      href="plan-your-visit/services.html"
                      className="btn btn-default btn-block-sm text-uppercase visible-xs"
                    >
                      View all services
                    </a>
                    <a
                      className="btn btn-link hidden-xs text-align-left"
                      href="plan-your-visit/services.html"
                    >
                      <p>
                        <strong>View all services</strong>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div
      className="site-collapse-menu site-collapse-menu--main hidden"
      id="site-collapse-menu"
      style={{ marginTop: 50 }}
    >
      <nav>
        <ul className="js--accordion" role="tablist">
          <li className="site-collapse-menu-item">
            <a href="whats-new.html">What's New</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="shop.html">Shop</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="dine.html">Dine</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="stay.html">Stay</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="entertain.html">Entertain</a>
          </li>
          <li className="site-collapse-menu-item">
            <a className="site-collapse-menu__level-0" href="#mm-5">
              Plan Your Visit
            </a>
            <ul className="collapse" id="mm-5">
              <li className="site-collapse-menu-item">
                <a href="plan-your-visit/opening-hours.html">Opening hours</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="plan-your-visit/getting-here.html">Getting here</a>
              </li>
              <li className="site-collapse-menu-item">
                <a href="u-by-emaar.html">U By Emaar</a>
              </li>
              <li>
                <a href="plan-your-visit/parking.html">Parking</a>
              </li>
            </ul>
          </li>
          <li className="site-collapse-menu-item">
            <a href="services.html">Services</a>
          </li>
        </ul>
      </nav>
      <nav className="inverse">
        <ul>
          <li className="site-collapse-menu-item">
            <a href="fashion-avenue.html">Fashion avenue</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="fountain-view.html">Fountain views</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="campaign/chinatown.html">Chinatown</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="offers.html">Offers</a>
          </li>
          <li className="site-collapse-menu-item">
            <a href="events.html">Events</a>
          </li>
        </ul>
      </nav>
      <nav>
        <ul className="js--accordion" role="tablist">
          <li
            className="site-collapse-menu-item menu_lan"
            id="language-selecter"
          >
            <a href="../ar/store-directory.html">العربية</a>
          </li>
          <li className="site-collapse-menu-item"></li>
        </ul>
      </nav>
    </div>
    <div className="visible-xs sfooter-menu">
      <div className="site-collapse-menu" id="site-collapse-menu-footer">
        <nav>
          <ul>
            <li className="site-collapse-menu-item">
              <a href="whats-new.html">What's New</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="shop.html">Shop</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="dine-directory.html">Dine</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="stay.html">Stay</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="entertain.html">Entertain</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="plan-your-visit/opening-hours.html">Plan Your Visit</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="services.html">Services</a>
            </li>
          </ul>
        </nav>
        <nav className="inverse">
          <ul>
            <li className="site-collapse-menu-item">
              <a href="fashion-avenue.html"> Fashion Avenue</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="offers.html">Offers</a>
            </li>
            <li className="site-collapse-menu-item">
              <a href="events.html"> Events</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    
    <style
      dangerouslySetInnerHTML={{
        __html:
          "\n\t@media (max-width: 480px) {\n    .sfooter-menu { display:none !important }\n    #footerContainer { display:none !important }\n    .site-footer__content { display:none !important }\n    }\n/*(1) owl-stage box*/\n.owl-carousel .owl-stage { display: flex; }\n/*(2) the direct div of each element in the owl carousel*/\n.owl-item { height: 100%; }\n    \n.owl-next, .owl-prev {\n    position: absolute;\n    transform: translateY(-50%);\n    -webkit-transform: translateY(-50%);\n}\n@media (min-width: 480px) {\n    .owl-next {\n        right: 0;\n        display: flex;\n        margin-top : 0.5% !important;\n    }\n    .owl-prev{\n        left: 0;\n        display: flex;\n        margin-top : 0.5% !important;\n    }    \n}\n@media (max-width: 480px) {\n    .owl-next {\n        right: 0;\n        display: flex;\n    }\n    .owl-prev{\n        left: 0;\n        display: flex;\n\n    }    \n    .owl-slider1  .owl-next {\n        \n        margin-top : 0.5% !important;\n    }\n    .owl-slider1  .owl-prev {\n      \n        margin-top : 0.5% !important;\n    }\n    .owl-slider  .owl-next {\n       \n        margin-top : 1% !important;\n    }\n    .owl-slider  .owl-prev {\n        \n        margin-top : 1% !important;\n    }\n   \n}    \n"
      }}
    />
    {/* Do Not Load ChatBot on Partners Page :: START */}
    {/*  */}
    {/* Do Not Load ChatBot on Partners Page :: END */}
   
  </div>
</>

  )
}

export default Stores