import React from 'react'
function Header() {
  return (
  <header className="site-header">
    <div className="site-header__leader">
      <div className="container">
        <div className="row">
          <div className="col-xs-24">
            <div className="site-header__language hidden-xs">
            
            </div>
            <div className="site-header__hamburger visible-xs">
              <a
                href="#"
                id="site-menu-button"
                data-toggle="toggle-menu"
                data-target=".site-header__hamburger"
              >
                <span className="icon-menu-rotate icon-menu" />
                <span className="icon-menu-rotate icon-menu-close" />
              </a>
            </div>
            <div className="site-header__logo">
              <a href="/" title="Westgate Shopping Mall">
                <img
                  src="./assets/images/logo.png"
                  height={100} alt="Westgate Shopping Mall logo"
                />
              </a>
            </div>
            <div className="site-header__search">
              <a
                href="http://www.emaargiftcard.com/"
                className="site-header-giftcard"
                target="_blank"
              >
                <span className="txt-gift hidden-xs">Buy Gift Voucher</span>
                <span className="icon-gift visible-xs" />
              </a>
              <a
                href="#"
                className="site-search-btn"
                id="site-search-button"
                data-toggle="toggle-search"
                data-target="#site-search"
              >
                <span data-view="close" className="hidden">
                  <span className="site-search-btn__label hidden-xs hidden-sm">
                    Close
                  </span>
                  <span className="site-search-btn__icon icon-cross" />
                </span>
                <span data-view="open" style={{ cursor: "pointer" }} onclick="">
                  <span className="site-search-btn__label hidden-xs">
                    Search
                  </span>
                  <span className="site-search-btn__icon icon-search" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav className="site-header__menu hidden-xs">
      <ul>
        <li>
          <a href="en/whats-new.html">What's New</a>
        </li>
        <li>
          <a href="en/shop.html" data-megamenu="#mm-shop">
            Shop <span className="icon icon-caret icon-caret-xs" />
          </a>
        </li>
        <li>
          <a href="en/dine.html" data-megamenu="#mm-dine">
            Dine <span className="icon icon-caret icon-caret-xs" />{" "}
          </a>
        </li>
        <li>
          <a href="en/stay.html" data-megamenu="#mm-stay">
            Stay <span className="icon icon-caret icon-caret-xs" />
          </a>
        </li>
        <li>
          <a href="en/entertain.html" data-megamenu="#mm-entertain">
            Entertain <span className="icon icon-caret icon-caret-xs" />
          </a>
        </li>
        <li>
          <a
            href="en/plan-your-visit/opening-hours.html"
            data-megamenu="#mm-plan-your-visit"
          >
            Plan Your Visit <span className="icon icon-caret icon-caret-xs" />
          </a>
        </li>
        <li>
          <a href="en/services.html">Services</a>
        </li>
      </ul>
    </nav>
    <div className="site-mega">
      <div className="site-mega__block" id="mm-empty" />
      <div className="site-mega__block" id="mm-shop">
        <div className="container">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Featured Categories
                </h5>
                <ul>
                  {/*<li><a href="/en/store-directory?categoryId=9">Electronics</a></li>*/}
                  <li>
                    <a href="en/store/electronics-1.html">Electronics</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=13">Fashion - Children</a></li>*/}
                  <li>
                    <a href="en/store/fashion-children.html">
                      Fashion - Children
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=4">Beauty & Fragrances</a></li>*/}
                  <li>
                    <a href="en/store/beauty-fragrances.html">
                      Beauty &amp; Fragrances
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=12">Fashion - Men</a></li>*/}
                  <li>
                    <a href="en/store/fashion-men.html">Fashion - Men</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=11">Fashion - Women</a></li>*/}
                  <li>
                    <a href="en/store/fashion-women.html">Fashion - Women</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=19">Home</a></li>*/}
                  <li>
                    <a href="en/store/home-1.html">Home</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=185">New Stores</a></li>*/}
                  <li>
                    <a href="en/store/new-stores-1.html">New Stores</a>
                  </li>
                  {/*<li><a href="/en/store-directory?categoryId=23">Toys</a></li>*/}
                  <li>
                    <a href="en/store/toys.html">Toys</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Brand
                </h5>
                <ul>
                  <li>
                    <a href="en/shop/bvlgari-1.html">Bulgari</a>
                  </li>
                  <li>
                    <a href="en/shop/cartier-fashion-avenue.html">Cartier</a>
                  </li>
                  <li>
                    <a href="en/shop/chloe.html">Chloé</a>
                  </li>
                  <li>
                    <a href="en/shop/chopard-boutique.html">Chopard Boutique</a>
                  </li>
                  <li>
                    <a href="en/shop/christian-louboutin-1.html">
                      Christian Louboutin
                    </a>
                  </li>
                  <li>
                    <a href="en/shop/henry-jacques.html">Henry Jacques</a>
                  </li>
                  <li>
                    <a href="en/shop/level-shoes.html">Level Shoes</a>
                  </li>
                  <li>
                    <a href="en/shop/louis-vuitton.html">Louis Vuitton</a>
                  </li>
                  <li>
                    <a href="en/shop/tiffany.html">Tiffany &amp; Co.</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Occasions
                </h5>
                <ul>
                  {/*<li><a href="/en/store-directory?tagId=5">Anniversary</a></li>*/}
                  <li>
                    <a href="en/store-tag/anniversary.html">Anniversary</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=184">Back to School</a></li>*/}
                  <li>
                    <a href="en/store-tag/back-to-school.html">
                      Back to School
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=15">Birthday</a></li>*/}
                  <li>
                    <a href="en/store-tag/birthday.html">Birthday</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=752">Eid</a></li>*/}
                  <li>
                    <a href="en/store-tag/eid.html">Eid</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=754">Festive Season</a></li>*/}
                  <li>
                    <a href="en/store-tag/festive-season.html">
                      Festive Season
                    </a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=757">Gifts for Her</a></li>*/}
                  <li>
                    <a href="en/store-tag/gifts-for-her.html">Gifts for Her</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=755">Gifts for Him</a></li>*/}
                  <li>
                    <a href="en/store-tag/gifts-for-him.html">Gifts for Him</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=759">Housewarming</a></li>*/}
                  <li>
                    <a href="en/store-tag/housewarming.html">Housewarming</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=412">Souvenirs</a></li>*/}
                  <li>
                    <a href="en/store-tag/souvenirs.html">Souvenirs</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=450">Travel</a></li>*/}
                  <li>
                    <a href="en/store-tag/travel.html">Travel</a>
                  </li>
                  {/*<li><a href="/en/store-directory?tagId=163">Wedding</a></li>*/}
                  <li>
                    <a href="en/store-tag/wedding.html">Wedding</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-9 col-height hidden-sm hidden-xs">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  In the spotlight
                </h5>
                <a
                  className="mega-menu__shop-spotlight"
                  href="en/campaign/chinatown.html"
                  title="Dubai Mall Chinatown"
                >
                  <figure>
                    <div
                      className="site-mega__img bg-image lazyload visible-sm-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-md-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-lg-block"
                      data-bgset="../dynamicassets.azureedge.net/uploads/zabeel_pages/desktop/64115e1ce5f7d.jpg"
                    />
                  </figure>
                </a>
                <p>
                  Chinatown is the latest addition to Dubai Mall, located on
                  the&nbsp;first floor opposite the Dubai Ice Ri...
                </p>
                <a
                  className="btn btn-link"
                  href="en/campaign/chinatown.html"
                  style={{ fontWeight: "800 !important" }}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="row margin-xs">
            <div className="col-xs-24">
              <a
                href="en/store-directory.html"
                className="btn btn-default btn-block"
              >
                View all shops
              </a>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n\t.site-mega__img {\n\t\theight: 250px!important;\n\t\tmargin: 15px 0;\n\t}\n\t"
            }}
          />
        </div>
      </div>
      <div className="site-mega__block" id="mm-dine">
        <div className="container">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height right-border">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Top picks
                </h5>
                <ul>
                  <li>
                    <a href="en/shop/angelina-1.html">Angelina</a>
                  </li>
                  <li>
                    <a href="en/shop/eataly.html">Eataly</a>
                  </li>
                  <li>
                    <a href="en/shop/galeries-lafayette-le-gourmet.html">
                      Galeries Lafayette Le Gourmet
                    </a>
                  </li>
                  <li>
                    <a href="en/shop/magnolia-bakery.html">Magnolia Bakery</a>
                  </li>
                  <li>
                    <a href="en/shop/markette-1.html">Markette</a>
                  </li>
                  <li>
                    <a href="en/shop/nandos.html">Nando's</a>
                  </li>
                  <li>
                    <a href="en/shop/social-house-1.html">Social House</a>
                  </li>
                  <li>
                    <a href="en/shop/the-cheesecake-factory.html">
                      The Cheesecake Factory
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Cuisine
                </h5>
                <ul>
                  {/*<li><a href="/en/dine-directory?tagId=3">American Cuisine</a></li>*/}
                  <li>
                    <a href="en/dine-tag/american-cuisine.html">
                      American Cuisine
                    </a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=188">Bakery</a></li>*/}
                  <li>
                    <a href="en/dine-tag/bakery.html">Bakery</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=213">British Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/british-food.html">British Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=581">Chinese</a></li>*/}
                  <li>
                    <a href="en/dine-tag/chinese.html">Chinese</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=611">Fast Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/fast-food.html">Fast Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=614">French</a></li>*/}
                  <li>
                    <a href="en/dine-tag/french-1.html">French</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=771">Indian Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/indian-food.html">Indian Food</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=312">Italian</a></li>*/}
                  <li>
                    <a href="en/dine-tag/italian-1.html">Italian</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=315">Japanese</a></li>*/}
                  <li>
                    <a href="en/dine-tag/japanese.html">Japanese</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=779">Middle Eastern Food</a></li>*/}
                  <li>
                    <a href="en/dine-tag/middle-eastern-food.html">
                      Middle Eastern Food
                    </a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=82">Kids</a></li>*/}
                  <li>
                    <a href="en/dine-tag/kids.html">Kids</a>
                  </li>
                  {/*<li><a href="/en/dine-directory?tagId=638">Healthy</a></li>*/}
                  <li>
                    <a href="en/dine-tag/healthy.html">Healthy</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-8 col-height right-border"></div>
              <div className="col-md-9 col-height hidden-sm hidden-xs">
                <h5
                  className="gs text-uppercase"
                  style={{ marginBottom: "20px !important" }}
                >
                  Featured restaurant
                </h5>
                <a
                  className="mega-menu__shop-spotlight"
                  href="en/shop/tanuki.html"
                  title="Tanuki"
                >
                  <figure>
                    <div
                      className="site-mega__img bg-image lazyload visible-xs-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/5cc04bc64b0e0.jpg"
                      data-alt="Tanuki"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-sm-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/5cc04bc64b0e0.jpg"
                      data-alt="Tanuki"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-md-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/5cc04bc64b0e0.jpg"
                      data-alt="Tanuki"
                    />
                    <div
                      className="site-mega__img bg-image lazyload visible-lg-block"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/5cc04bc64b0e0.jpg"
                      data-alt="Tanuki"
                    />
                  </figure>
                </a>
                <p>
                  Tanuki provides a gateway to a unique Asian culinary
                  experience, serving dishes and drinks inspired ...
                </p>
                <a
                  className="btn btn-link"
                  href="en/shop/tanuki.html"
                  style={{ fontWeight: "800 !important" }}
                >
                  Find out more
                </a>
              </div>
            </div>
          </div>
          <div className="row margin-xs">
            <div className="col-xs-24">
              <a
                href="en/dine-directory.html"
                className="btn btn-default btn-block"
              >
                View all restaurants
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-stay">
        <div className="container megamenuStay">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="col-md-5 col-sm-8 col-height">
                <div className="containerMegamenuStay col-md-5 col-sm-8 col-height" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-entertain">
        <div className="container megamenuEntertainment">
          <div className="row margin-xs">
            <div className="row-height">
              <div className="containerMegamenuEntertainment col-md-5 col-sm-8 col-height" />
            </div>
          </div>
        </div>
      </div>
      <div className="site-mega__block" id="mm-plan-your-visit">
        <div className="container">
          <div className="row margin-xs">
            <div className="col-md-10 col-sm-8 right-border">
              <div className="panel-card">
                <article>
                  <figure>
                    <div
                      className="panel-card__img bg-image lazyload"
                      data-bgset="https://dynamicassets.azureedge.net/uploads/advertisement/mainPicture/64a7f46e5dad6.jpg"
                      data-alt="Spend, Spin and Win Big Experiences"
                    />
                  </figure>
                  <main>
                    <p className="padding-xs" />
                    <p>
                      From 10 July to 7 October 2023, Shop for AED 500 or more
                      at the Dubai Mall with Visa cards to spin for a chance to
                      win instant prizes that match your passion. Visit the Visa
                      activation on the First Floor near Dubai Aquarium to play
                      and win.
                    </p>
                    <p>
                      <a href="en/pages/spend-spin-and-win-big-event.html">
                        T&amp;Cs Apply.
                      </a>
                    </p>
                    <p />
                    <a
                      className="btn btn-link"
                      href="en/offer-detail/spend-spin-and-win-big-experiences.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
            </div>
            <div className="col-md-4 col-sm-8">
              <ul>
                <li>
                  <a href="en/plan-your-visit/opening-hours.html">
                    Opening hours{" "}
                  </a>
                </li>
                <li>
                  <a href="en/plan-your-visit/getting-here.html">
                    Getting here
                  </a>
                </li>
                <li>
                  <a href="en/u-by-emaar.html">U By Emaar</a>
                </li>
                <li>
                  <a href="en/plan-your-visit/parking.html">Parking</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a className="site-mega__close" href="#">
      <span className="icon-cross" />
    </a>
    <div className="site-mega__overlay" />
  </header>
  )
}

export default Header