import React,{useState,useEffect} from 'react'
import Home from "./pages/home"
import Contact from "./pages/contact"
import Stores from "./pages/stores"
import Faqs from "./pages/faqs"
import Privacy from "./pages/privacy"
import { BrowserRouter,Routes, Route } from 'react-router-dom'
import Header from "./pages/partials/header"
import Footer from "./pages/partials/footer"
import Chatbot from "react-chatbot-kit"
import 'react-chatbot-kit/build/main.css'
import config from "./chatbot/config"
import MessageParser from "./chatbot/MessageParser"
import ActionProvider from "./chatbot/ActionProvider"
import api from "./api/config"
function App() {
//saving bot chat
  const [showBot, toggleBot] = useState(false)
  const saveMessages = (messages, HTMLString) => {
    localStorage.setItem('chat_messages', JSON.stringify(messages))
  }
  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem('chat_messages'))
    return messages
  }
  //end bot chat saving

  //callling API
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Using axios to get data
    api.get('/api/hello')
      .then(response => {
        setMessage(response.data.message);
      })
      .catch(error => {
        console.error("There was an error!", error)
      })
  }, [])
  console.log(message)

  //END API CALL

  return (
    <div>
    <Header />
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/stores" element={<Stores/>} />
        <Route path="/faqs" element={<Faqs/>} />
        <Route path="/privacy" element={<Privacy/>} />
    </Routes>
    </BrowserRouter>
    {/*
    <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
  /> */}
   <div className='App'>
      {showBot && (
        <Chatbot
          config={config}
          actionProvider={ActionProvider}
          messageHistory={loadMessages()}
          messageParser={MessageParser}
          saveMessages={saveMessages}
          runInitialMessagesWithHistory
        />
      )}
      <button onClick={() => toggleBot((prev) => !prev)}>Bot</button>
      <p>{message}</p>
      
    </div>
    <Footer /> 
    
    </div>
  )
}

export default App