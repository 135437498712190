import React from 'react'
import CookieModal from "./cookie"

function Footer() {
  return (
    <div>
    <footer className="site-footer section">
      <div className="site-logo-lined hidden-xs sg-mb-30px">
        <a href="en/index.html" title="Westgate Shopping Mall">
          <span className="site-logo-lined--lines">
         
            <img
              src="./assets/images/logo.png"
              height={50} alt="Westgate Shopping Mall logo"
            />
          </span>
        </a>
      </div>
      <div className="site-footer__content">
        <div className="container sg-mb-30px">
          <div className="row">
            <div className="col-md-7 col-sm-6 centered-block-sm clear-padding-md">
              <h5 className="gs padding-xs text-uppercase follow-us-head">
                Follow Us
              </h5>
              <ul className="follow-us">
                <li>
                  <a
                    href="https://www.facebook.com/westgateshoppingmall"
                    target="_blank"
                    className="social_share"
                    data-platform="Facebook"
                  >
                    <span className="icon icon-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/WestgateMallKe"
                    target="_blank"
                    className="social_share"
                    data-platform="Twitter"
                  >
                    <img
                      src="https://staticassets.azureedge.net/assets/images/twitter.svg"
                      className="icon"
                      style={{ width: 36, height: 36 }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCIxoVOet1m1laZhJ3Hiobwg"
                    target="_blank"
                    className="social_share"
                    data-platform="Youtube"
                  >
                    <span className="icon icon-youtube-play" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/westgatemallke/"
                    target="_blank"
                    className="social_share"
                    data-platform="Instagram"
                  >
                    <span className="icon icon-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-10 col-sm-11 centered-block-sm">
              <h5 className="gs padding-xs text-uppercase">
                Subscribe to our Newsletter
              </h5>
              <div data-newsletter="footer">
                <form
                  className="signup-newsletter"
                  data-parsley-validate=""
                  noValidate=""
                >
                  <div className="step-1">
                    <div className="input-group newsletter-group">
                      <input
                        type="hidden"
                        name="source"
                        defaultValue="website"
                      />
                      <input type="hidden" name="language" defaultValue="en" />
                      <input
                        id="hidden-captcha"
                        name="hidden-captcha"
                        type="hidden"
                      />
                      <input
                        type="email"
                        name="email"
                        data-parsley-type="email"
                        className="form-control"
                        placeholder="Email Address"
                        maxLength={100}
                        autoComplete="off"
                        data-parsley-required="true"
                        data-parsley-required-message="This value is required."
                        data-parsley-type-message="Please enter a valid email address."
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-primary text-uppercase"
                          type="submit"
                        >
                          Sign Up
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="step-2 hidden">
                    <p className="sg-mt-10px">
                      Thank you for subscribing to our newsletter
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-7 col-sm-7 centered-block-sm clear-padding-md">
              <h5 className="gs padding-xs text-uppercase">Download Westgate app</h5>
              <ul className="store-badges">
                <li style={{ maxWidth: "120px !important" }}>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img
                      src="./assets/images/appstore.webp"
                      alt="Westgate Shopping Mall App Store"
                      className="img-responsive"
                    />
                  </a>
                </li>
                <li style={{ maxWidth: "120px !important" }}>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img
                      src="./assets/images/playstore.webp"
                      alt="Westgate Shopping Mall Google play"
                      className="img-responsive"
                    />
                  </a>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
        <div className="solid-line solid-line--grey hidden-xs" />
        <div className="container sg-mb-30px sg-mt-30px hidden-xs">
          <div className="row">
            <div className="col-md-6  col-sm-6">
              <section className="site-footer-links">
                <header>
                  <h5 className="gs text-uppercase">Visitor Information</h5>
                </header>
                <ul>
                  <li>
                    <a href="en/services.html">Services</a>
                  </li>
                  <li>
                    <a href="en/map.html" target="_blank">
                      Mall Map
                    </a>
                  </li>
                  <li>
                    <a href="http://www.emaargiftcard.com/" target="_blank">
                      Westgate Gift Voucher
                    </a>
                  </li>
                  <li>
                    <a href="en/services/the-lounge.html">The Lounge</a>
                  </li>
                  <li>
                    <a href="en/more-from-emaar.html">More from Westgate</a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-md-6 col-sm-6">
              <section className="site-footer-links">
                <header>
                  <h5 className="gs text-uppercase">About us</h5>
                </header>
                <ul>
                  <li>
                    <a href="en/about-us/about-the-dubai-mall.html">
                      About Westgate
                    </a>
                  </li>
                  <li>
                    <a href="http://www.visitdubai.com/en" target="_blank">
                      Store Directory
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://properties.emaar.com/en/press-release-listing/"
                      target="_blank"
                    >
                      Blog
                    </a>
                  </li>
                  {/*<li><a href="https://careers.emaar.com/" target="_blank">Careers</a></li>*/}
                  <li>
                    <a
                      href="https://properties.emaar.com/en/other-emaar-businesses/emaar-malls/"
                      target="_blank"
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="https://careers.emaar.com/" target="_blank">
                      Careers
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-md-6 col-sm-6">
              <section className="site-footer-links">
                <header>
                  <h5 className="gs text-uppercase">Policy and partnership</h5>
                </header>
                <ul>
                  <li>
                    <a
                      href="https://retailservices.emaarmalls.com/"
                      target="_blank"
                    >
                      Tenants' Portal
                    </a>
                  </li>
                  <li>
                    <a href="https://rental.emaarmalls.com/" target="_blank">
                      Leasing Enquiries
                    </a>
                  </li>
                  <li>
                    <a href="en/about-us/privacy-policies.html">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="en/about-us/terms-and-conditions.html">
                      Terms and Conditions
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-md-6 col-sm-6">
              <section className="site-footer-links">
                <header>
                  <h5 className="gs text-uppercase">Contact us</h5>
                </header>
                <ul>
                  <li>
                    <p className="footer-fix">
                      <a href="tel:+254715557775">
                        Tel: +254-715-557-775
                      </a>
                    </p>
                  </li>
                  <li>
                    <a href="mailto:marketing@westgate.co.ke?subject=Inquiry">
                    marketing@westgate.co.ke
                    </a>
                  </li>
                  <li>
                    <p>
                      To provide feedback or suggestions,
                      
                    </p>
                    
                      
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
        <div
          className="container sg-mb-15px sg-mt-30px visible-xs"
          data-footer-mobile-menu=""
        >
          <div className="row">
            <section className="site-footer-mobile-links">
              <ul className="js--accordion full-width">
                <li className="site-collapse-menu-item text-left text-uppercase">
                  <a
                    className="site-collapse-menu__level-0"
                    href="#mm-1"
                    target="_blank"
                  >
                    Visitor Information
                  </a>
                  <ul className="collapse" id="mm-1">
                    <li>
                      <a href="en/map.html" target="_blank">
                        Mall Map
                      </a>
                    </li>
                    <li>
                      <a href="http://www.emaargiftcard.com/" target="_blank">
                        Westgate Gift Voucher
                      </a>
                    </li>
                    <li>
                      <a href="en/services/the-lounge.html">The Lounge</a>
                    </li>
                    <li>
                      <a
                        href="https://emaarnewcustomer.secure.force.com/wecare?type=1&from=vKteIARjY%2baJ2b6JsyhkZiLs7M%2b5KD0y%2fXUow0DFmQ0%3d&Lang=en"
                        target="_blank"
                      >
                        Customer Survey
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="site-collapse-menu-item text-left text-uppercase">
                  <a className="site-collapse-menu__level-0" href="#mm-2">
                    About us
                  </a>
                  <ul className="collapse" id="mm-2">
                    <li>
                      <a href="en/about-us/about-the-dubai-mall.html">
                        About Dubai Mall
                      </a>
                    </li>
                    <li>
                      <a href="http://www.visitdubai.com/en" target="_blank">
                        Dubai Tourism
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://properties.emaar.com/en/press-release-listing/"
                        target="_blank"
                      >
                        Media Centre
                      </a>
                    </li>
                    <li>
                      <a href="https://careers.emaar.com/" target="_blank">
                        Careers
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://properties.emaar.com/en/other-emaar-businesses/emaar-malls/"
                        target="_blank"
                      >
                        Westgate Mall
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://retailservices.emaarmalls.com/"
                        target="_blank"
                      >
                        E-Services/Tenants' Portal
                      </a>
                    </li>
                    <li>
                      <a href="https://rental.emaarmalls.com/" target="_blank">
                        Leasing Enquiries
                      </a>
                    </li>
                    <li>
                      <a href="en/about-us/privacy-policies.html">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="en/about-us/terms-and-conditions.html">
                        Terms &amp; Conditions
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="site-collapse-menu-item text-left text-uppercase">
                  <a className="site-collapse-menu__level-0" href="#mm-3">
                    Contact us
                  </a>
                  <ul className="collapse" id="mm-3">
                    <li>
                      <a href="tel:800 38224 6255">
                        UAE ONLY: 800 DUBAI MALL (
                        <span style={{ textDecoration: "underline" }}>
                          800 38224 6255
                        </span>
                        )
                      </a>
                    </li>
                    <li>
                      <a href="mailto:enquiry@thedubaimall.com?subject=Website Enquiry">
                        enquiry@thedubaimall.com
                      </a>
                    </li>
                    <li>
                      <p className="wecare-data-intro">
                        To provide feedback or suggestions,
                        <br />
                        Please click on the below Wecare icon to connect with
                        us.
                      </p>
                      <a
                        className="wecare-data-slide-img"
                        href="javascript:void(0);"
                      >
                        <img src="../staticassets.azureedge.net/assets/images/we-care-logo-en.png" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="wecare-data-slide-cnt"
                        href="https://emaarsales.my.salesforce-sites.com/wecare"
                        target="_blank"
                      >
                        Feedback
                      </a>
                    </li>
                    <li>
                      <a
                        className="wecare-data-slide-cnt"
                        href="https://emaarsales.my.salesforce-sites.com/wecare"
                        target="_blank"
                      >
                        Enquiry
                      </a>
                    </li>
                    <li>
                      <a
                        className="wecare-data-slide-cnt"
                        href="https://emaarsales.my.salesforce-sites.com/wecare"
                        target="_blank"
                      >
                        Suggestion
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="footer__lang-sel-wrap full-width">
                <ul className="full-width">
                  <select
                    className="footer__lang-sel margin-top-sm"
                    name="Select Language"
                  >
                    <option value="EN">English</option>
                    <option value="FR">French</option>
                    <option value="GE">German</option>
                  </select>
                </ul>
              </div>
            </section>
          </div>
        </div>
        <div style={{ display: "none" }}>
          KGMpIENocmlzIFNjaGFyZGlqbiAyMDE1LTIwMTY=
        </div>
      </div>
      {/* EMAAR BRAND GUIDELINES START*/}
      <div className="solid-line solid-line--grey hidden-xs" />
      <div id="footerContainer" className="" style={{}}>
        <div id="footerOverlay" className="footeroverlay" />
        <div id="emaarFooter" className="">
          <div id="footerLinks" className="footer-links">
            <div className="container">
              <a id="btnCloseGroup" className="footer-close icon-cross" />
              <div className="footer-top">
                <div className="footer-wrapper footer-columns">
                  <div className="footer-column">
                    <ul>
                      <li>
                        <a href="https://www.emaar.com/en" target="_blank">
                          Properties
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.emaarhospitality.com/"
                          target="_blank"
                        >
                          Hospitality
                        </a>
                      </li>
                      <li>
                        <a href="index.html" target="_blank">
                          Malls
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-column">
                    <ul>
                      <li>
                        <a
                          href="https://www.burjkhalifa.ae/en/"
                          target="_blank"
                        >
                          Burj Khalifa
                        </a>
                      </li>
                      <li>
                        <a href="http://www.dubaiopera.com/" target="_blank">
                          Dubai Opera
                        </a>
                      </li>
                      <li>
                        <a href="http://www.eii.ae/" target="_blank">
                          Industries &amp; Investments
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-column">
                    <ul>
                      <li>
                        <a href="http://www.reelcinemas.ae/en/" target="_blank">
                          Reel Cinemas
                        </a>
                      </li>
                      <li>
                        <a href="https://www.dubaiicerink.com/" target="_blank">
                          Dubai Ice Rink
                        </a>
                      </li>
                      <li>
                        <a href="https://www.vrparkdubai.com/" target="_blank">
                          VR Park
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-column">
                    <ul>
                      <li>
                        <a
                          href="https://dubai.kidzania.com/en-ae"
                          target="_blank"
                        >
                          Kidzania
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.thedubaiaquarium.com/"
                          target="_blank"
                        >
                          Dubai Aquariam &amp; Underwater Zoo
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="footer-wrapper footer-columns">
              <div className="footer-left footer-column">
                <div className="footer-content">
                  <div className=" footer-columns">
                    <div className="footer-column one-fourth">
                      
                    </div>
                    <div className="footer-column">
                      {" "}
                      <a id="btnShowGroup" className="footer-group">
                        View All Stores
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-right footer-column">
                <div className="footer-content">
                  <p>
                   Westgate Shoppinng Mall is a property of Sony Holdings Limited
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* EMAAR BRAND GUIDELINES END */}
      <div className="site-footer__disclaimer">
        <p>
          <span className="logo--footer" />
          <span className="site-footer__disclaimer__inner">
            © 2023 Westgate Shopping Mall. All rights reserved.
          </span>
        </p>
      </div>
    </footer>
    <div
      className="container-fluid md-full-width md-reset-padding"
      style={{
        backgroundColor: "rgba(51, 51, 51, 0.9)",
        position: "fixed",
        bottom: "-1px",
        zIndex: 1000
      }}
      id="cookie_bar"
    >
        <CookieModal />
   {/* cookie policy */}
    </div>
    <div className="site-search-drop" id="site-search">
      <div className="container">
        {/* search input */}
        <section className="search-section search-input">
          <div className="col-xs-24 sg-mb-40px">
            <form
              action="#"
              id="search"
              className="search-form"
              onsubmit="return false;"
            >
              <fieldset>
                <input
                  id="q"
                  name="q"
                  className="input--search"
                  placeholder="Search Westgate Shopping Mall"
                  type="search"
                  autoComplete="off"
                />
                <button
                  data-toggle="search-debug"
                  className="search-submit"
                  id="shop-search"
                >
                  <span className="icon icon-search" />
                </button>
                <span
                  className="icon icon-search fix"
                  id="icon-search-topSearch"
                />
              </fieldset>
            </form>
          </div>
        </section>
        {/* results */}
        <section className="search-section search-results--lead">
          <div className="row">
            <div className="col-xs-24 sg-mb-50px">
              <ul className="sub-nav--search">
                <li>
                  <a
                    href="#"
                    target="_blank"
                    className="active"
                    data-toggle="filter-search"
                    data-filter="all"
                  >
                    All (<span id="srch_count_all">0</span>)
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    data-toggle="filter-search"
                    data-filter="src__shops"
                  >
                    Shop (<span id="srch_count_shop">0</span>)
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    data-toggle="filter-search"
                    data-filter="src__offers"
                  >
                    Offers (<span id="srch_count_offer">0</span>)
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                    data-toggle="filter-search"
                    data-filter="src__events"
                  >
                    Events (<span id="srch_count_event">0</span>)
                  </a>
                </li>
              </ul>
              <div className="drop-category">
                <select
                  id="shop-category2"
                  className="search-shop-category no-transform"
                  style={{
                    border: "1px solid #000",
                    backgroundColor: "#FFF",
                    borderRadius: 0
                  }}
                >
                  <option value="all" selected="selected">
                    All
                  </option>
                  <option value="cat--01">Shop</option>
                  <option value="cat--02">Offers</option>
                  <option value="cat--03">Events</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-24 sg-mb-30px">
              <h3 id="yourSearch" className="text-center">
                Showing search results for ""
              </h3>
              {/*<h2 id="yourSearchXs" class="text-center visible-xs">Showing search results for &quot;&quot;</h2>*/}
            </div>
          </div>
        </section>
        <section className="search-section search-results--content src__shops">
          <div className="row sg-mb-20px">
            <div className="col-xs-18">
              <div className="column-border-bottom column-border-bottom--pull-right">
                <h5 className="text-uppercase">Shops</h5>
              </div>
            </div>
            <div className="col-xs-6">
              {/*<div class="select" style="border: 1px solid #000; padding: 0 5px; overflow: hidden !important; height: 36px;">
                                  <select name="category" id="search-shop-category" class="select-styled" style="width: 100% !important; border:0px; outline:0px; font-size: 14px !important; background-color: transparent; height: 34px;">

                                  </select>
                              </div>*/}
              <select
                name="category"
                id="search-shop-category"
                className="search_me"
              />
            </div>
          </div>
          <div className="row">
            <div className="container padding-bottom-xs">
              <div
                className="row padding-xs block-grid-legend"
                style={{ marginTop: "30px !important" }}
              >
                <div className="col-md-12" />
                <div className="col-md-12 text-right">
                  <i className="new-icon" />{" "}
                  <span className="inner">Accepts Emaar Gift Card</span>
                </div>
              </div>
            </div>
            <div
              className="container block-grid search-results--content__all sg-mb-10px"
              id="filteredShop-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-matched-stores-head"
                  >
                    <span>Matched Results</span>
                  </h5>
                </div>
              </div>
              <div className="row" id="filteredShop" />
              <div className="row pagination">
                <a
                  href="#"
                  id="showMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
            <div
              className="container block-grid search-results--content__all sg-mb-10px hidden"
              id="related-filteredShop-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-related-stores-head"
                  >
                    <span>Related Results</span>
                  </h5>
                </div>
              </div>
              <div className="row" id="related-filteredShop" />
              <div className="row pagination">
                <a
                  href="#"
                  id="relatedshop-showMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
            {/*<div id="filteredShop" class="container block-grid search-results--content__all sg-mb-10px ">
                          </div>*/}
            {/*<section class="site-section">
                              <div class="container">
                                  <div class="row pagination">
                                      <a href="#" id="showMore" style="text-decoration:none !important;" target="_self" class="pagination__txt">Load more</a>
                                  </div>
                              </div>
                          </section>*/}
          </div>
        </section>
        <section className="search-section search-results--content src__offers">
          <div className="row">
            <div className="col-xs-24 sg-mb-20px">
              <div className="column-border-bottom">
                <h5 className="text-uppercase">Offers</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="container block-grid search-results--content__all sg-mb-10px"
              id="filteredOffer-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-matched-offers-head"
                  >
                    <span>Matched Results</span>
                  </h5>
                </div>
              </div>
              <div className="col-xs-24 sg-mb-20px" id="filteredOffer" />
              <div className="row pagination">
                <a
                  href="#"
                  id="offershowMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
            {/*<div id="filteredOffer" class="col-xs-24 sg-mb-20px"></div>*/}
            <div
              className="container block-grid search-results--content__all sg-mb-10px hidden"
              id="related-filteredOffer-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-related-offers-head"
                  >
                    <span>Related Results</span>
                  </h5>
                </div>
              </div>
              <div
                className="col-xs-24 sg-mb-20px"
                id="related-filteredOffer"
              />
              <div className="row pagination">
                <a
                  href="#"
                  id="related-offershowMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="search-section search-results--content src__events">
          <div className="row">
            <div className="col-xs-24 sg-mb-20px">
              <div className="column-border-bottom">
                <h5 className="text-uppercase">Events</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="container block-grid search-results--content__all sg-mb-10px"
              id="filteredEvent-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-matched-events-head"
                  >
                    <span>Matched Results</span>
                  </h5>
                </div>
              </div>
              <div className="col-xs-24 sg-mb-20px" id="filteredEvent" />
              <div className="row pagination">
                <a
                  href="#"
                  id="eventshowMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
            {/*<div id="filteredEvent" class="col-xs-24 sg-mb-40px"></div>*/}
            <div
              className="container block-grid search-results--content__all sg-mb-10px hidden"
              id="related-filteredEvent-parent"
            >
              <div className="row">
                <div className="col-xs-24">
                  <h5
                    className="text-center text-uppercase bordered"
                    id="top-srch-related-events-head"
                  >
                    <span>Related Results</span>
                  </h5>
                </div>
              </div>
              <div
                className="col-xs-24 sg-mb-20px"
                id="related-filteredEvent"
              />
              <div className="row pagination">
                <a
                  href="#"
                  id="related-eventshowMore"
                  style={{ textDecoration: "none !important" }}
                  target="_self"
                  className="pagination__txt"
                >
                  Load more
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="search-section search-preloading">
          <div className="row">
            <div
              className="col-xs-24 sg-mb-20px"
              id="search-results-preloading"
            />
          </div>
        </section>
        {/* links */}
        <section className="search-section">
          <div className="row">
            <div className="col-xs-24">
              <h3 className="text-center hidden-xs">
                Haven't found what you're looking for?
              </h3>
              <h2 className="text-center visible-xs">
                Haven't found what you're looking for?
              </h2>
            </div>
          </div>
          <div className="row text-center reset-margin">
            <a
              href="#site-search"
              id="main-top-srch-refine-srch"
              className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase sg-mt-20px sg-mb-40px"
              target="_self"
              style={{ color: "#000", border: "1px solid #000" }}
              data-scroll-to=""
            >
              Refine search <i className="arrow" />
            </a>
            <a
              href="#site-search"
              id="main-top-srch-srch-again"
              className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase sg-mt-20px sg-mb-40px hidden"
              target="_self"
              style={{ color: "#000", border: "1px solid #000" }}
              data-scroll-to=""
            >
              Search again <i className="arrow" />
            </a>
          </div>
        </section>
        {/* top searches */}
        <section className="search-section top-searches">
          <div className="row">
            <div className="col-xs-24 sg-mb-20px">
              <h3 className="text-center">Featured Search</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-24 sg-mb-20px">
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/cartier-fashion-avenue.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/files/store/elevatedPic/56c184eda3357.png.optimized.jpg"
                        data-alt="Cartier"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Cartier</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Founded in 1847 by Louis Francois Cartier, the House of
                      Cartier has been producing pieces with a unity of style
                      for generations, no matter the differences in artistic
                      expression.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/cartier-fashion-avenue.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/cartier-fashion-avenue.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/chanel.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic/56e7b4368897e.png.optimized.jpg"
                        data-alt="Chanel"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Chanel</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      “I want to be part of what happens” – Mademoiselle Chanel
                      This aspiration, coupled with a visionary mind, has made
                      Chanel a unique brand.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/chanel.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/chanel.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/christian-louboutin-1.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic3/5b8e24ec14d01.jpg"
                        data-alt="Christian Louboutin"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Christian Louboutin</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Christian Louboutin established his business in the heart
                      of Paris in 1991, first with a collection for Women
                      followed by a Men’s collection a few years later, both
                      recognizable by the signature red lacquered sole. 2014
                      welcomed the launch of Christian Louboutin Beauté. With a
                      prolific collection of women’s and men’s shoes, handbags
                      and small leather goods. Christian Louboutin now has 145
                      boutiques available around the world.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/christian-louboutin-1.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/christian-louboutin-1.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/graff.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/files/store/elevatedPic/56cb06662106d.png.optimized.jpg"
                        data-alt="Graff"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Graff</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Graff Diamonds-The most Fabulous Jewels In the World
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/graff.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/graff.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/harry-winston-1.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/files/store/elevatedPic/569c9b0097cae.png.optimized.jpg"
                        data-alt="Harry Winston"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Harry Winston</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Founded in New York City in 1932, the brand continues to
                      set the standard as the “King of Diamonds” in fine jewelry
                      and high-end watchmaking.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/harry-winston-1.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/harry-winston-1.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/level-shoes.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic3/612e38f171056.jpg"
                        data-alt="Level Shoes"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Level Shoes</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Level Shoes is the world's largest luxury shoe store
                      spanning over 96,000 square feet with over 200+ global
                      brands inclusive of adidas, Axel Arigato, Amina Muaddi,
                      Burberry, Jacquemus, Malone Souliers, Nike and many more,
                      as well as 23 designer boutiques, such as Chanel, Louis
                      Vuitton, Christian Dior and Saint Laurent. With a range of
                      services spanning from personalisation to an in-store foot
                      spa, Level Shoes has a dedicated Concierde Team to support
                      with personal shopping requests, VAT processing and
                      regional deliveries.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/level-shoes.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/level-shoes.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
              <div className="panel-card">
                <article>
                  <figure>
                    <a href="en/shop/van-cleef.html">
                      <div
                        className="panel-card__img bg-image lazyload sg-mb-10px"
                        data-bgset="https://dynamicassets.azureedge.net/uploads/offer/elevatedPic3/6334058cbd36a.jpg"
                        data-alt="Van Cleef & Arpels"
                      />
                    </a>
                  </figure>
                  <main>
                    <h5 className="text-uppercase sg-mb-5px">
                      <strong>Van Cleef &amp; Arpels</strong>
                    </h5>
                    <div
                      className="sg-mb-5px"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%"
                      }}
                    >
                      Loyal to its founders, the architecture of the Van Cleef
                      &amp; Arpels boutique preserves the spirit of the high
                      jewelry creations.
                    </div>
                    <a
                      className="btn btn-link sg-mb-20px hidden-xs"
                      href="en/shop/van-cleef.html"
                    >
                      Find out more
                    </a>
                    <a
                      className="btn btn-default visible-xs sg-mt-20px sg-mb-20px "
                      href="en/shop/van-cleef.html"
                    >
                      Find out more
                    </a>
                  </main>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      className="modal-custom-container modal-newsletter-popup"
      data-newsletter=""
    >
      <div className="modal-custom-container__view">
        <div className="display-table">
          <div className="display-table-cell col-middle">
            <div className="modal-custom-container__box text-center animated fadeInUp">
              <a
                className="modal-custom-close"
                href="#"
                data-newsletter-close=""
              >
                <span className="icon-cross" />
              </a>
              <div className="step-1">
                <h3 className="sg-mb-20px">Subscribe to Westgate Shopping Mall Newsletter</h3>
                <p className="sg-mb-20px">
                  Be the first to know about exciting events and new products
                  available at Dubai Mall, delivered straight to your inbox.
                </p>
                <form
                  id="newsletter-signup-form"
                  data-parsley-validate=""
                  noValidate=""
                >
                  <div className="input-group newsletter-group sg-mb-40px">
                    <input type="hidden" name="source" defaultValue="website" />
                    <input type="hidden" name="language" defaultValue="en" />
                    <input
                      type="email"
                      name="email"
                      data-parsley-type="email"
                      className="form-control"
                      placeholder="Email Address"
                      maxLength={100}
                      autoComplete="off"
                      data-parsley-required="true"
                      data-parsley-required-message="This value is required."
                      data-parsley-type-message="Please enter a valid email address."
                    />
                    <span className="input-group-btn">
                      <button
                        className="btn btn-primary text-uppercase"
                        type="submit"
                      >
                        Sign Up
                      </button>
                    </span>
                  </div>
                </form>
                <p className="text-muted">
                  <a target="_blank" href="en/about-us/privacy-policies.html">
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className="step-2 hidden">
                <h3 className="sg-mb-25px">Congratulations</h3>
                <p className="sg-mb-30px">
                  You'll be the first to know about Dubai Mall news and updates.
                  In the meantime, why not explore the variety of outstanding
                  stores and entertainment options on our site?
                </p>
                <div className="row">
                  <div className="col-sm-18 col-sm-offset-3">
                    <a
                      className="btn btn-primary btn-block"
                      id="newsletter_close"
                      href="#"
                      data-newsletter-close=""
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-custom-container__overlay" />
    </div>
    </div>
  )
}

export default Footer