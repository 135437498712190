import React, { useState, useEffect } from 'react';

function Slider({ images }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [images]);

  const goBack = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  const goForward = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  return (
    <div className="slider-container">
      {images.map((image, index) => (
        <div
          key={index}
          className="slide"
          style={{
            backgroundImage: `url(${image})`,
            opacity: currentSlide === index ? 1 : 0,
            transition: 'opacity 1s'
          }}
        />
      ))}
      <button className="arrow arrow-left" onClick={goBack}>&lt;</button>
      <button className="arrow arrow-right" onClick={goForward}>&gt;</button>

      <style jsx>{`
        .slider-container {
          position: relative;
          width: 100%;
          height: 690px;     /* This controls the height of your slider */
          overflow: hidden;
        }

        .slide {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: cover;    /* This ensures the image covers the full size of the container */
          background-position: center;
          background-repeat: no-repeat;  /* This ensures the image doesn't repeat */
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.5);
          border: none;
          cursor: pointer;
          font-size: 1.5em;
          padding: 10px 15px;
          transition: background-color 0.3s;
        }

        .arrow:hover {
          background-color: rgba(255, 255, 255, 0.7);
        }

        .arrow-left {
          left: 10px;
        }

        .arrow-right {
          right: 10px;
        }
      `}</style>
    </div>
  );
}

export default Slider;
