import { createChatBotMessage } from 'react-chatbot-kit'
import { DateTime } from 'luxon'
//generate Greeting
function greetUser() {
  const nowInNairobi = DateTime.now().setZone("Africa/Nairobi")
  const hour = nowInNairobi.hour;
  if (hour >= 4 && hour < 12) {
    return "Good morning!";
  } else if (hour >= 12 && hour < 16) {
    return "Good afternoon!";
  } else {
    return "Good evening!";
  }
}
//end greeting
const botName = 'Samantha'
const config = {
  initialMessages: [createChatBotMessage(`${greetUser()}, I'm ${botName} from Westgate Shopping Mall. How may I help you today?`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#D62D2D',
    },
    chatButton: {
      backgroundColor: '#D62D2D',
    },
  },
}

export default config