import React from "react"
import Slider from "./partials/homeslider"

//home sliders
const images = [
  './assets/images/mall.jpg',
  './assets/images/bata.webp',
  './assets/images/eys.webp',
  './assets/images/salute.webp',
  './assets/images/CA4-03.jpg',
  './assets/images/puma.webp',
  './assets/images/pool.jpg',
  './assets/images/gym-banner.jpg',
  './assets/images/img4.webp'

]

//home sliders

class Home extends React.Component {
  render() {
    return (
      <>
        <div className="constant-dots">
          <div className="site">
            <div className="site-content home-page">
              <div className="site-sections">
                <nav className="site-sections__menu hidden-xs">
                  <ul>
                    <li>
                      <a href="#">Fashion avenue</a>
                    </li>
                    <li>
                      <a href="en/fountain-view.html">Fountain views</a>
                    </li>
                    <li>
                      <a href="en/campaign/chinatown.html">Chinatown</a>
                    </li>
                    <li>
                      <a href="en/offers.html">Offers</a>
                    </li>
                    <li>
                      <a href="en/events.html">Events</a>
                    </li>
                  </ul>
                </nav>
                {/*slider start */}
                <div
                  style={{ height: '100%' }}
                  className="block-container home-slider"
                >
                  <Slider images={images} />
                </div>

                {/*slider end */}
                <div className="block-container">
                  <div className="block-abs">
                    <div
                      className="block block-border-right col-md-14 col-sm-12 hidden-xs"
                      data-block=""
                    >
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                          {/*<div class="bg-image lazyload" id="bg-img-fashion-avenue"></div>*/}
                          <div
                            data-bgset="./assets/images/gym-banner.jpg"
                            className="bg-image lazyload"
                          />
                        </figure>
                      </div>
                    </div>
                    <div
                      className="block block-border-left col-md-10 col-sm-12 block-white"
                      data-block=""
                    >
                      <div className="block-content" id="blockFashionAvenue">
                        <div className="display-table">
                          <div
                            className="display-table-cell col-middle text-center"
                            style={{ position: "relative" }}
                          >
                            <h5
                              className="sg-mb-50px text-uppercase visible-xs hidden-sm visible-md visible-lg"
                              style={{
                                position: "absolute",
                                top: "10%",
                                left: 0,
                                right: 0
                              }}
                            >
                              W-exclusive
                            </h5>
                            <div className="hidden-xs visible-sm">
                              <br />
                              <h5 className="sg-mb-50px text-uppercase">
                                W-Exclusive
                              </h5>
                            </div>
                            <h2
                              className="sg-mb-30px text-uppercase block__push-in hidden-xs hidden-sm"
                              style={{ fontWeight: 400 }}
                            >
                              Westgate
                              <br />
                              Gym
                            </h2>
                            <h3
                              className="sg-mb-30px text-uppercase block__push-in visible-sm"
                              style={{ fontWeight: 400 }}
                            >
                              Westgate
                              <br />
                              Gym
                            </h3>
                            <h1
                              className="sg-mb-30px text-uppercase block__push-in visible-xs"
                              style={{ fontWeight: 400 }}
                            >
                              Westgate
                              <br />
                              Gym
                            </h1>
                            <div className="col-lg-12 col-lg-offset-6 col-md-18 col-md-offset-3">
                              <p className="clearfix sg-mb-30px">
                                Exclusive Wellness & Fitness gym, fitted with world class top of the range Technogym equipment that sits over 3,000 sqm of spacious workout area, with an indoor heated pool, a health bar and studios for gym classes and activities for an experience like no other.
                              </p>
                              <a
                                href="#"
                                className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase hidden-xs visible-sm"
                                style={{ color: "#000", borderColor: "#000" }}
                              >
                                Learn more
                              </a>
                            </div>
                            <div
                              className="visible-xs hidden-sm visible-md visible-lg"
                              style={{
                                position: "absolute",
                                bottom: "10%",
                                left: 15,
                                right: 15
                              }}
                            >
                              <a
                                href="#"
                                className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase hidden-xs visible-md visible-lg"
                                style={{ maxWidth: "50%", margin: "auto" }}
                              >
                                Learn more
                              </a>
                              <a
                                href="#"
                                className="btn btn-primary-transparent btn-primary-transparent--diap-sm text-uppercase visible-xs hidden-sm"
                                style={{ color: "#FFF", borderColor: "#FFF" }}
                              >
                                Learn more
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient visible-xs" />
                      <div className="block-visuals visible-xs">
                        <figure className="block-cover-img">
                          <div
                            data-bgset="./assets/images/gym-banner.jpg"
                            className="bg-image lazyload visible-xs-block"
                          />
                          <div
                            data-bgset="./assets/images/gym-banner.jpg"
                            className="bg-image lazyload visible-sm-block"
                          />
                          <div
                            data-bgset="./assets/images/gym-banner.jpg"
                            className="bg-image lazyload visible-md-block"
                          />
                          <div
                            data-bgset="./assets/images/gym-banner.jpg"
                            className="bg-image lazyload visible-lg-block"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    className="block-padding"
                    data-block-height=""
                    data-xs={1}
                    data-sm={1}
                    data-md={1}
                    data-lg={1}
                  />
                </div>
                <div className="block-container">
                  <div className="block-abs">
                    <div className="block col-xs-24" data-block="">
                      <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                        <div
                          className="container-fluid md-full-width md-reset-padding"
                          id="home_view1"
                        >
                          <div className="row">
                            <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                              <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                <strong>Entertainment</strong>
                              </h5>
                              <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                                <strong>Entertainment</strong>
                              </h5>
                              <h1
                                className="sg-mb-10px text-white col-sm-16"
                                style={{ marginLeft: 0, paddingLeft: 0 }}
                              >
                                Westgate VIP Cinema
                              </h1>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                              <span className="sg-mb-10px text-white col-sm-16">
                                <p className="sg-mb-25px text-white hidden-xs">
                                  Westgate Cinema is integrated with 3D digital cinema projection systems
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-22 col-sm-offset-half md-pull-in-line">
                              <a
                                href="en/entertain-detail/dubai-aquarium-umderwatep-zoo-1.html"
                                className="btn btn-default-transparent btn-block-sm text-uppercase margin-right-xs-px"
                              >
                                Cinema lineup
                              </a>
                              <a
                                href="en/entertain.html"
                                className="btn btn-default-transparent btn-block-sm text-uppercase"
                              >
                                Book Tickets
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                          <div
                            className="bg-image lazyload"
                            data-bgset="./assets/images/cinema-banner.jpg [(max-width: 766px] |./assets/images/cinema-banner.jpg [(max-width: 1022px] | ./assets/images/cinema-banner.jpg"
                            data-alt="Westgate Cinema "
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div
                    className="block-padding"
                    data-block-height=""
                    data-xs={1}
                    data-sm={1}
                    data-md={1}
                    data-lg={1}
                  />
                </div>
                <div className="block-container">
                  <div className="block-abs">
                    <div
                      className="block block-border-right col-md-12 col-sm-24 "
                      id="home_view2"
                      data-block=""
                    >
                      <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                        <div className="container-fluid md-reset-padding md-full-width">
                          <div className="row">
                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                              <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                <strong>Dine</strong>
                              </h5>
                              <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                                <strong>Dine</strong>
                              </h5>
                              <div className="visible-xs">
                                <h1 className="sg-mb-10px text-white" />
                              </div>
                              <div className="hidden-xs">
                                <h2 className="sg-mb-10px text-white" />
                              </div>
                              <div className="row">
                                <div className="col-md-20 col-sm-14">
                                  <p className="sg-mb-25px text-white hidden-xs" />
                                </div>
                              </div>
                              <a
                                href="en/shop.html"
                                className=" btn btn-default-transparent btn-block-sm margin-right-xs-px text-uppercase"
                              >
                                Explore more
                              </a>
                              <a
                                href="en/dine.html"
                                className="btn btn-default-transparent btn-block-sm text-uppercase"
                              >
                                Discover dining
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                          <div
                            data-bgset="./assets/images/dinning.jpg"
                            className="bg-image lazyload"
                            data-alt="Westgate Shopping Mal restaurants"
                          />
                        </figure>
                      </div>
                    </div>
                    <div
                      className="block block-border-left col-md-12 col-sm-24"
                      data-block=""
                    >
                      <div className="block-wrap">
                        <div
                          className="block-bottom sg-mb-50px sg-mb-20px-xs"
                          id="home_view3"
                        >
                          <div className="container-fluid md-reset-padding md-full-width">
                            <div className="row">
                              <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                                <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                  <strong>Shop</strong>
                                </h5>
                                <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                                  <strong>Shop</strong>
                                </h5>
                                <div className="visible-xs">
                                  <h1 className="sg-mb-10px text-white" />
                                </div>
                                <div className="hidden-xs">
                                  <h2 className="sg-mb-10px text-white" />
                                </div>
                                <div className="row">
                                  <div className="col-md-20 col-sm-12">
                                    <p className="sg-mb-25px text-white hidden-xs" />
                                  </div>
                                </div>
                                <a
                                  href="#"
                                  target="_blank"
                                  className="exploreMoreStayCTA btn btn-default-transparent btn-block-sm margin-right-xs-px text-uppercase"
                                >
                                  Explore shops
                                </a>
                                <a
                                  href="en/stay.html"
                                  className="btn btn-default-transparent btn-block-sm text-uppercase"
                                >
                                  Discover Shops
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="block-gradient" />
                        <div className="block-visuals">
                          <figure className="block-cover-img">
                            <div
                              data-bgset="./assets/images/CA4-03.jpg"
                              className="bg-image lazyload"
                              data-alt="Westgate Shopping Mall"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="block-padding"
                    data-block-height=""
                    data-xs={2}
                    data-sm={2}
                    data-md={1}
                    data-lg={1}
                  />
                </div>
                <div className="block-container">
                  <div className="block-abs">
                    <div
                      className="block block-border-right col-md-12 col-sm-24 "
                      id="home_view2"
                      data-block=""
                    >
                      <div className="block-bottom sg-mb-50px sg-mb-20px-xs">
                        <div className="container-fluid md-reset-padding md-full-width">
                          <div className="row">
                            <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                              <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                <strong>Tech</strong>
                              </h5>
                              <h5 className="text-uppercase sg-mb-25px text-white visible-lg spaced">
                                <strong>Tech</strong>
                              </h5>
                              <div className="visible-xs">
                                <h1 className="sg-mb-10px text-white" />
                              </div>
                              <div className="hidden-xs">
                                <h2 className="sg-mb-10px text-white" />
                              </div>
                              <div className="row">
                                <div className="col-md-20 col-sm-14">
                                  <p className="sg-mb-25px text-white hidden-xs" />
                                </div>
                              </div>
                              <a
                                href="en/shop.html"
                                className=" btn btn-default-transparent btn-block-sm margin-right-xs-px text-uppercase"
                              >
                                Explore more
                              </a>
                              <a
                                href="en/dine.html"
                                className="btn btn-default-transparent btn-block-sm text-uppercase"
                              >
                                Discover tech
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="block-gradient" />
                      <div className="block-visuals">
                        <figure className="block-cover-img">
                          <div
                            data-bgset="./assets/images/salute.webp"
                            className="bg-image lazyload"
                            data-alt="Westgate Shopping Mal electronic stores"
                          />
                        </figure>
                      </div>
                    </div>
                    <div
                      className="block block-border-left col-md-12 col-sm-24"
                      data-block=""
                    >
                      <div className="block-wrap">
                        <div
                          className="block-bottom sg-mb-50px sg-mb-20px-xs"
                          id="home_view3"
                        >
                          <div className="container-fluid md-reset-padding md-full-width">
                            <div className="row">
                              <div className="col-xs-22 col-xs-offset-1 md-pull-in-line">
                                <h5 className="text-uppercase sg-mb-15px text-white hidden-lg spaced">
                                  <strong>Fashion</strong>
                                </h5>
                                <h5 className="text-uppercase sg-mb-15px text-white visible-lg spaced">
                                  <strong>Fashion</strong>
                                </h5>
                                <div className="visible-xs">
                                  <h1 className="sg-mb-10px text-white" />
                                </div>
                                <div className="hidden-xs">
                                  <h2 className="sg-mb-10px text-white" />
                                </div>
                                <div className="row">
                                  <div className="col-md-20 col-sm-12">
                                    <p className="sg-mb-25px text-white hidden-xs" />
                                  </div>
                                </div>
                                <a
                                  href="#"
                                  target="_blank"
                                  className="exploreMoreStayCTA btn btn-default-transparent btn-block-sm margin-right-xs-px text-uppercase"
                                >
                                  Explore shops
                                </a>
                                <a
                                  href="en/stay.html"
                                  className="btn btn-default-transparent btn-block-sm text-uppercase"
                                >
                                  Discover Fashion
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="block-gradient" />
                        <div className="block-visuals">
                          <figure className="block-cover-img">
                            <div
                              data-bgset="./assets/images/fashion.webp"
                              className="bg-image lazyload"
                              data-alt="Westgate Shopping Mall Fashion"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="block-padding"
                    data-block-height=""
                    data-xs={2}
                    data-sm={2}
                    data-md={1}
                    data-lg={1}
                  />
                </div>
                <div className="clearfix">
                  <section
                    className="site-section"
                    style={{ width: "100%", overflow: "hidden" }}
                  >
                    <div className="container sg-mb-25px">
                      <div className="row">
                        <div className="col-xs-24">
                          <div className="visible-xs">
                            <h2 className="text-center sg-mt-40px sg-mb-25px">
                              Discover Events &amp; Offers
                            </h2>
                          </div>
                          <div className="hidden-xs">
                            <h3 className="text-center sg-mt-40px">
                              Discover Events &amp; Offers
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="container no-gutter-md sg-mb-30pxm "
                      id="home_view4"
                    >
                      <div className="slick-slider-container">
                        <div className="slick-slider-container__row slick-slider-with-text">
                          <div className="row">
                            <div
                              className="slick-events-offers-slider pull-nav-up diap-dots"
                              data-prefilter=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row hidden-xs sg-mb-40px">
                        <div className="col-xs-24 text-center">
                          <div className="sg-mt-20px" id="home_view5">
                            <a
                              href="en/events.html"
                              className="btn btn-default btn-block-sm margin-right-xs-px"
                            >
                              Discover events
                            </a>
                            <a
                              href="en/offers.html"
                              className="btn btn-default btn-block-sm"
                            >
                              Discover offers
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="visible-xs">
                      <br />
                    </div>
                  </section>
                  <section className="site-section">
                    <div className="view-panel-banner">
                      <div className="view-panel-banner__rel">
                        <div className="row">
                          <div className="col-xs-22 col-xs-offset-1 text-center">
                            <div className="visible-xs visible-sm">
                              <h1 className="sg-mb-20px text-white">
                                Westgate <br />
                                Kids Corner
                              </h1>
                            </div>
                            <div className="visible-md visible-lg">
                              <h2 className="sg-mb-20px text-white">
                                Westgate <br />
                                Kids Corner
                              </h2>
                            </div>
                            <a
                              href="en/whats-new.html"
                              className="btn btn-default-transparent btn-block-sm text-uppercase"
                            >
                              Learn more
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="view-panel-banner__gradient half-opacity" />
                      <div className="view-panel-banner__visual">
                        <div
                          className="bg-image lazyload"
                          data-bgset="./assets/images/kids-banner.jpg"
                          data-alt="Dubai Mall"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div
            className="site-collapse-menu site-collapse-menu--main hidden"
            id="site-collapse-menu"
            style={{ marginTop: 50 }}
          >
            <nav>
              <ul className="js--accordion" role="tablist">
                <li className="site-collapse-menu-item">
                  <a href="en/whats-new.html">What's New</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/shop.html">Shop</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/dine.html">Dine</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/stay.html">Stay</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/entertain.html">Entertain</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a className="site-collapse-menu__level-0" href="#mm-5">
                    Plan Your Visit
                  </a>
                  <ul className="collapse" id="mm-5">
                    <li className="site-collapse-menu-item">
                      <a href="en/plan-your-visit/opening-hours.html">
                        Opening hours
                      </a>
                    </li>
                    <li className="site-collapse-menu-item">
                      <a href="en/plan-your-visit/getting-here.html">Getting here</a>
                    </li>
                    <li className="site-collapse-menu-item">
                      <a href="en/u-by-emaar.html">U By Emaar</a>
                    </li>
                    <li>
                      <a href="en/plan-your-visit/parking.html">Parking</a>
                    </li>
                  </ul>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/services.html">Services</a>
                </li>
              </ul>
            </nav>
            <nav className="inverse">
              <ul>
                <li className="site-collapse-menu-item">
                  <a href="#">Fashion avenue</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/fountain-view.html">Fountain views</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/campaign/chinatown.html">Chinatown</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/offers.html">Offers</a>
                </li>
                <li className="site-collapse-menu-item">
                  <a href="en/events.html">Events</a>
                </li>
              </ul>
            </nav>
            <nav>
              <ul className="js--accordion" role="tablist">
                <li
                  className="site-collapse-menu-item menu_lan"
                  id="language-selecter"
                >
                  <a href="ar.html">العربية</a>
                </li>
                <li className="site-collapse-menu-item"></li>
              </ul>
            </nav>
          </div>
          <div className="visible-xs sfooter-menu">
            <div className="site-collapse-menu" id="site-collapse-menu-footer">
              <nav>
                <ul>
                  <li className="site-collapse-menu-item">
                    <a href="en/whats-new.html">What's New</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/shop.html">Shop</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/dine-directory.html">Dine</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/stay.html">Stay</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/entertain.html">Entertain</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/plan-your-visit/opening-hours.html">
                      Plan Your Visit
                    </a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/services.html">Services</a>
                  </li>
                </ul>
              </nav>
              <nav className="inverse">
                <ul>
                  <li className="site-collapse-menu-item">
                    <a href="#"> Fashion Avenue</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/offers.html">Offers</a>
                  </li>
                  <li className="site-collapse-menu-item">
                    <a href="en/events.html"> Events</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n\t@media (max-width: 480px) {\n    .sfooter-menu { display:none !important }\n    #footerContainer { display:none !important }\n    .site-footer__content { display:none !important }\n    }\n/*(1) owl-stage box*/\n.owl-carousel .owl-stage { display: flex; }\n/*(2) the direct div of each element in the owl carousel*/\n.owl-item { height: 100%; }\n    \n.owl-next, .owl-prev {\n    position: absolute;\n    transform: translateY(-50%);\n    -webkit-transform: translateY(-50%);\n}\n@media (min-width: 480px) {\n    .owl-next {\n        right: 0;\n        display: flex;\n        margin-top : 0.5% !important;\n    }\n    .owl-prev{\n        left: 0;\n        display: flex;\n        margin-top : 0.5% !important;\n    }    \n}\n@media (max-width: 480px) {\n    .owl-next {\n        right: 0;\n        display: flex;\n    }\n    .owl-prev{\n        left: 0;\n        display: flex;\n\n    }    \n    .owl-slider1  .owl-next {\n        \n        margin-top : 0.5% !important;\n    }\n    .owl-slider1  .owl-prev {\n      \n        margin-top : 0.5% !important;\n    }\n    .owl-slider  .owl-next {\n       \n        margin-top : 1% !important;\n    }\n    .owl-slider  .owl-prev {\n        \n        margin-top : 1% !important;\n    }\n   \n}    \n"
            }}
          />
        </div>
      </>


    )

  }
}
export default Home;